import { AppModules, AppState } from 'common/redux/sitka'
import moment from 'moment-timezone'
import { SitkaModule } from 'olio-sitka'
import { put, select } from 'redux-saga/effects'
import { defaultOrganization, Organization } from './organization_core'

import { env } from 'common/util/env'

// supported languages
// make sure to import these from moment-timezone, not moment
import 'moment/locale/da'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pt'
import 'moment/locale/sv'

export class OrganizationModule extends SitkaModule<Organization, AppModules> {
  public moduleName: string = 'organization'
  public defaultState: Organization = defaultOrganization
  public static selectOrganization(state: AppState): Organization {
    return state.organization
  }

  public setLocalization(language: string) {
    // set language
    const targetLanguage = language.toLowerCase()

    // Only initialize if the language is not English.  This
    // saves us credits with LocalizeJS.
    if (targetLanguage !== 'en') {
      window.Localize.initialize({
        disableWidget: true,
        key: env('LOCALIZE_API_KEY'),
        rememberLanguage: false,
        blockedClasses: ['team-name', 'excl-lz'],
        targetLanguage,
        translateTitle: false,
        translateMetaTags: false,
      })
    } else {
      // This is hacky.  It removes this little snippet of CSS that LocalizeJS
      // adds to the DOM to hide all text while it waits for iniitalize() to be
      // called. Because it never is, the text only shows after a second or two
      // when a timer fires in their library.
      const localizeCSS =
        '*{color:transparent!important;text-shadow:none!important}::-webkit-input-placeholder{color:transparent !important;} :-moz-placeholder{color:transparent!important;}'
      document.querySelectorAll('style').forEach(style => {
        if (style.innerText === localizeCSS) {
          style.remove()
        }
      })
    }

    // set moment
    moment.locale(targetLanguage)
  }

  public *set(state: Organization) {
    const oldState = yield select(OrganizationModule.selectOrganization)
    if (oldState.language !== state.language) {
      this.setLocalization(state.language)
    }

    yield put(this.setState(state, true))
  }
}
