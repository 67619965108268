import React, { FC } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { TimeProps } from '../../util/components/page_container'
import { useContentMargin } from '../../util/content_margin'
import { Sidebar } from '../../components/molecules/sidebar/sidebar'
import { InfoModalType } from 'common/redux/info_modal/info_modal_core'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { Session } from 'common/redux/session/session_core'
import { desktopStylesWrapper, ContainerPropsModalMode } from 'assets/shared_css_styles'
import { HostMember, LocationType } from 'common/redux/meetings/meetings_core'
import { Maybe } from 'graphql'

function getBookingMetadata(bookedAppointment: BookedAppointment) {
  const meeting = bookedAppointment?.meeting
  const timezone = bookedAppointment?.timezone
  const startDate = moment(meeting?.start).tz(timezone?.momentCode || '')
  const endDate = moment(meeting?.end).tz(timezone?.momentCode || '')
  const metadata = {
    date: startDate.format('ddd, ll'),
    startTime: startDate.format('LT').toLocaleLowerCase(),
    endTime: endDate.format('LT').toLocaleLowerCase(),
  }
  return metadata
}

interface WeekNavigatorProps {
  readonly id: string
  readonly weeks: ReadonlyArray<string>
  readonly currentWeek: string
  readonly timeProps: TimeProps
  readonly session: Session
  readonly bookedAppointment: BookedAppointment
  readonly setCurrentWeek: (week: string) => void
  readonly moreTimes?: boolean
  readonly setInfoModal: (isOpen: boolean, id: string, modalType?: InfoModalType) => void
  readonly setTimezoneModal: (isOpen: boolean) => void
  readonly locationType?: Maybe<LocationType>
  readonly location?: Maybe<string>
  readonly hostMember?: HostMember
}

export const WeekNavigator: FC<WeekNavigatorProps> = ({
  id,
  weeks,
  currentWeek,
  timeProps,
  session: { sessionType, mode },
  bookedAppointment,
  setCurrentWeek,
  moreTimes,
  setInfoModal,
  setTimezoneModal,
  location,
  locationType,
  hostMember,
}) => {
  const changingBooking = sessionType === 'reschedule' || sessionType === 'cancel'

  const contentMargin = useContentMargin()
  const isModal = mode === 'modal'

  if (weeks.length === 0) {
    return <div />
  }

  if (changingBooking) {
    const metadata = getBookingMetadata(bookedAppointment)
    return (
      <WeekNavigatorContainer contentMargin={contentMargin} isModal={isModal}>
        <Sidebar
          {...timeProps}
          {...{
            setTimezoneModal,
            setInfoModal,
            id,
            weeks,
            currentWeek,
            setCurrentWeek,
            moreTimes,
            hostMember,
          }}
          {...metadata}
        />
      </WeekNavigatorContainer>
    )
  }

  return (
    <WeekNavigatorContainer contentMargin={contentMargin} isModal={isModal}>
      <Sidebar
        {...timeProps}
        {...{
          setTimezoneModal,
          setInfoModal,
          id,
          weeks,
          currentWeek,
          setCurrentWeek,
          moreTimes,
          location,
          locationType,
          hostMember,
        }}
      />
    </WeekNavigatorContainer>
  )
}

interface WeekNavigatorContainerProps extends ContainerPropsModalMode {
  readonly contentMargin: number
}

const WeekNavigatorContainer = styled.div<WeekNavigatorContainerProps>`
  display: none;

  .sidebar-card {
    display: grid;
    grid-template-rows: auto 1px minmax(auto, 192px);
  }

  ${({ isModal }: WeekNavigatorContainerProps) => desktopStylesWrapper(isModal).wrapper`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    width: 300px;
    float: left;
    max-height: calc(100vh - ${({ isModal }: WeekNavigatorContainerProps) =>
      isModal ? `160px` : `94px`});
    overflow-y: scroll;
    margin-left: 24px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`
