import { SitkaModule } from 'olio-sitka'
import moment from 'moment-timezone'
import { put, select } from 'redux-saga/effects'
import { Timezone } from './timezone_core'
import { deprecatedZones } from './deprecated_zones'
import { AppModules, AppState } from 'common/redux/sitka'
import { handleActivity } from '../activity/activity_module'
import { Meeting, Meetings } from '../meetings/meetings_core'
import { MeetingsModule } from '../meetings/meetings_module'
import { AvailableTimesModule } from '../available_times/available_times_module'
import { AvailableTimes } from '../available_times/available_times_core'
import { parseAvailableTimes, parseBucketedTimes } from '../../util/available_time_utils'

const getUserTimezone = (): Timezone => {
  const guess = moment.tz.guess()
  const momentCode = deprecatedZones[guess] ? deprecatedZones[guess] : guess
  return {
    momentCode,
    display: momentCode.replace(/_/g, ' '),
    isLocked: false,
    name: momentCode.toUpperCase().replace(/\//g, '_'),
  }
}

export class TimezoneModule extends SitkaModule<Timezone, AppModules> {
  public moduleName: string = 'timezone'
  public defaultState: Timezone = getUserTimezone()
  public static selectTimezone(state: AppState): Timezone {
    return state.timezone
  }

  public *handleRenderTimezoneModal() {
    yield handleActivity('requestedTimeZoneChange')
  }

  public *handleChangeTimezone(timezone: Timezone) {
    yield this.setTimezone(timezone)

    const currentTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)

    const meeting: Meeting = yield this.modules.meetings.getSelectedMeeting()

    // Recompute display times with new timezone selection
    yield this.modules.availableTimes.set(
      parseAvailableTimes(currentTimes.sort, meeting.duration, timezone.momentCode)
    )

    // Recompute bucketedTimes with new timezone (might change the days to display)
    yield this.modules.bucketedTimes.set(parseBucketedTimes(currentTimes.sort, timezone.momentCode))

    // Cleanup timezone modal state
    yield this.modules.timezoneModal.setTimezoneModal(false)

    yield handleActivity('selectedTimeZone')
  }

  public *setMeetingTimeZone(timezone: Timezone): {} {
    const meetings: Meetings = yield select(MeetingsModule.selectMeetings)

    const updatedMeetings = {
      ...meetings,
      items: {
        ...meetings.items,
        [meetings.selected]: {
          ...meetings.items[meetings.selected],
          timezone: timezone,
        },
      },
    }
    yield this.modules.meetings.set(updatedMeetings)
  }

  public *setTimezone(timezone: Timezone): {} {
    yield put(this.setState({ ...timezone }, true))
  }

  public *resetTimezone(): {} {
    yield put(this.setState({ ...getUserTimezone() }, true))
  }
}
