import { SitkaModule } from 'olio-sitka'
import { Error, defaultError } from './error_core'
import { AppModules, AppState } from 'common/redux/sitka'
import { put } from 'redux-saga/effects'
import { Intent } from '@blueprintjs/core'

export class ErrorModule extends SitkaModule<Error, AppModules> {
  public moduleName: string = 'error'
  public defaultState: Error = defaultError
  public static selectError(state: AppState): Error {
    return state.error
  }

  public *handleClearError() {
    yield put(this.setState(defaultError, true))
  }

  public *handleShowError(text: string, intent: Intent, translate: boolean = false) {
    yield put(this.setState({ text, intent, translate, shown: false }, true))
  }
}
