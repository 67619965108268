import { SitkaModule } from 'olio-sitka'
import moment from 'moment-timezone'
import { AppModules } from 'common/redux/sitka'

import { Client, HandledResp } from 'common/api/client'
import { Meeting, Meetings } from '../meetings/meetings_core'
import { MeetingsModule } from '../meetings/meetings_module'
import { call, select } from 'redux-saga/effects'
import { getQueryStringFormValues } from 'common/util/query_string_utils'
import { validateCustomFormValues } from 'common/redux/bootstrap/bootstrap_util'

import {
  parseMeetingTypesRemote,
  parseFieldsRemote,
  parseTeamAndMeetingTypeToOrganization,
  parseTeamToOrganizationLegacy,
} from '../remote/remote_booking_transform'
import {
  parseTimezonesRemote,
  computeTimezoneValues,
  parseTimezoneOptionsToTimezonesRemote,
} from '../remote/remote_timezones_transform'
import { transformRemoteHostMembers } from '../remote/remote_host_members_transform'
import { Session } from '../session/session_core'
import { SessionModule } from '../session/session_module'
import { BookedAppointment } from '../booked_appointment/booked_appointment_core'
import { BookedAppointmentModule } from '../booked_appointment/booked_appointment_module'
import {
  parseAvailableTimesRemote,
  parseAvailableTimesRemoteGql,
  parseRescheduleTimesRemoteGql,
} from '../remote/remote_available_times_transform'
import { transformSingleMeetingType } from '../remote/remote_team_transform'
import { transformRemoteAttendee } from './ingress_util'
import { parseErrors } from '../submit_update_booking/submit_update_booking_util'
import { AvailableTimesModule } from '../available_times/available_times_module'
import { DynamicLoadingModule } from '../dynamic_loading/dynamic_loading_module'
import { AvailableTimes, defaultAvailableTimes } from '../available_times/available_times_core'
import { Intent } from '@blueprintjs/core'
import { appStrings } from 'assets/app_strings'
import { TimezoneModal, TimezoneOption } from '../timezone_modal/timezone_modal_core'
import { DynamicLoading } from '../dynamic_loading/dynamic_loading_core'
import { parseAvailableTimes } from 'common/util/available_time_utils'
import { defaultTimezone, Timezone } from '../timezone/timezone_core'
import { SchedulerMeetingNode, SchedulerMeetingTypeNode, SchedulerTeamNode } from 'graphql'
import { Team, TimezoneRemote } from '../remote/remote'
import { TimezoneModule } from '../timezone/timezone_module'
import { TimezoneModalModule } from '../timezone_modal/timezone_modal_module'
import { Payment } from '../payment/payment_core'

const { FETCH_BOOKED } = appStrings.errorMessage

export class IngressModule extends SitkaModule<{}, AppModules> {
  public moduleName: string = 'ingress'
  public defaultState = {}

  // sets up the data for starting a brand new booking
  public *fetchMeetingTypes(teamSlug: string): {} {
    // get booking data
    const client = new Client()
    const resp: HandledResp = yield call(client.getTeamAndMeetingTypes, teamSlug)

    const { message, code } = parseErrors(resp)
    if (message) {
      // check if the page was not found
      if (code === 201 || code === 200 || code === 204) {
        // set default language for error page
        this.modules.organization.setLocalization('en')
        yield this.modules.session.setSessionMeta('error', 'error404')
      } else {
        yield this.modules.error.handleShowError(message, Intent.DANGER)
        yield this.modules.session.setSessionMeta('error', 'serverError')
      }
      return true
    }

    const timezones = yield this.fetchTimezones()
    const meetings = parseMeetingTypesRemote(resp.items, teamSlug, timezones)

    yield this.setProfileLegacy(resp.items.data?.team)

    if (!!meetings) {
      // this should not fail unless there is a server error
      yield this.modules.meetings.set(meetings)
    }
  }

  public *fetchMeetingType(teamSlug: string, slug: string): {} {
    // get booking data
    const client = new Client()
    const resp: HandledResp = yield call(client.getTeamAndMeetingType, teamSlug, slug)
    const { message, code } = parseErrors(resp)
    if (message) {
      // check if the page was not found
      if (code === 201 || code === 200 || code === 204) {
        // set default language for error page
        this.modules.organization.setLocalization('en')
        yield this.modules.session.setSessionMeta('error', 'error404')
      } else {
        yield this.modules.error.handleShowError(message, Intent.DANGER)
        yield this.modules.session.setSessionMeta('error', 'serverError')
      }
      return true
    }

    const groomedResp = transformSingleMeetingType(resp.items)
    const timezones: ReadonlyArray<TimezoneRemote> = yield this.fetchTimezones()
    const meetings = parseMeetingTypesRemote(groomedResp, teamSlug, timezones)

    yield this.setProfileLegacy(resp.items.data?.team)

    if (!!meetings) {
      // this nullcheck should never fail unless there is a server error
      yield this.modules.meetings.set(meetings)

      // check if there is a selected meeting or check where selected meeting is set from here
      // if selected meeting.usesLocalTimezone === true, then set to the timezone in the meeting
      const selectedMeeting = meetings.items[meetings.selected]
      if (selectedMeeting && selectedMeeting.usesLocalTimezone) {
        yield this.modules.timezone.setTimezone(selectedMeeting.timezone)
      }
    }
  }

  public *fetchFormFields() {
    const meeting: Meeting = yield this.modules.meetings.getSelectedMeeting()
    const { slug, teamSlug, locationType } = meeting

    const client = new Client()
    const resp: HandledResp = yield call(client.getFormFields, slug, teamSlug)
    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }

    const queryStringFormValues = getQueryStringFormValues(window.location.search)
    const fields = parseFieldsRemote(resp.items, locationType)

    const prePopulatedFormFields = validateCustomFormValues(queryStringFormValues, fields)

    if (!!fields) {
      // this should not fail unless server error
      yield this.modules.fields.set(fields)
      yield this.modules.fieldValues.set(prePopulatedFormFields)
    }
  }

  public *handleFetchAvailableTimes(clear: boolean) {
    yield this.fetchAvailableTimes(clear)
  }

  public *createPayment(meetingTypeId: string) {
    const client = new Client()
    const resp: HandledResp = yield call(client.submitCreatePayment, { meetingTypeId })

    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }

    if (!resp.items.data.createPayment?.data) {
      return
    }

    const paymentState: Payment = {
      id: resp.items.data.createPayment?.data?.id,
      redirectUrl: resp.items.data.createPayment?.data?.providerPaymentPageUrl,
    }

    yield this.modules.payment.set(paymentState)
  }

  // This saga sets up the data required for rehydrating a previously completed booking.
  // if the action clear is false this function will trigger another page load of times
  public *fetchAvailableTimes(clear: boolean) {
    const session: Session = yield select(SessionModule.selectSession)
    // prevents load on exit
    if (session.location !== 'time') {
      return
    }
    const bookedAppointment: BookedAppointment = yield select(
      BookedAppointmentModule.selectBookedAppointment
    )
    const { externalId, timezone: bookedAppointmentTimezone } = bookedAppointment
    if (session.sessionType === 'reschedule' && externalId && bookedAppointmentTimezone) {
      const error: boolean = yield this.pollUntilResult(
        externalId,
        '',
        '',
        bookedAppointmentTimezone.momentCode
      )
      return error
    }

    if (clear) {
      yield this.modules.availableTimes.clearTimes()
    }
    // making a standard booking flow check for meeting
    const meeting: Meeting = yield this.modules.meetings.getSelectedMeeting()
    const timezone: Timezone = yield select(TimezoneModule.selectTimezone)
    if (!meeting) {
      return
    }
    const {
      slug,
      teamSlug,
      timezone: { momentCode: meetingMomentCode },
      usesLocalTimezone,
      selectedHostMember,
    } = meeting

    // poll until at least one meeting is fetched
    const error: boolean = yield this.pollUntilResult(
      '',
      slug,
      teamSlug,
      usesLocalTimezone ? meetingMomentCode : timezone.momentCode,
      selectedHostMember?.id || ''
    )
    return error
  }

  public *fetchHostMembersForSelectedMeeting() {
    const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
    const { slug, teamSlug } = meetings.items[meetings.selected]
    const client = new Client()
    const resp: HandledResp = yield call(client.getAvailableHostMembers, slug, teamSlug)
    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }

    const hostMembersForSelected = transformRemoteHostMembers(resp.items)
    const updatedMeeting = {
      ...meetings.items[meetings.selected],
      hostMembers: hostMembersForSelected,
    }
    const newState = {
      ...meetings,
      items: {
        ...meetings.items,
        [updatedMeeting.id]: updatedMeeting,
      },
    }

    yield this.modules.meetings.set(newState)
    return false
  }

  public *fetchAvailableTime(time: string) {
    const meetings: Meetings = yield select(MeetingsModule.selectMeetings)
    const timezone: Timezone = yield select(TimezoneModule.selectTimezone)

    const {
      slug,
      teamSlug,
      duration,
      timezone: { momentCode: meetingMomentCode },
      usesLocalTimezone,
      selectedHostMember,
    } = meetings.items[meetings.selected]
    const endTime = moment(time)
      .tz(usesLocalTimezone ? meetingMomentCode : timezone.momentCode)
      .add(duration + 30, 'minutes')
      .format()

    const client = new Client()
    const resp: HandledResp = yield call(
      client.getAvailableTimes,
      slug,
      teamSlug,
      time,
      endTime,
      selectedHostMember?.id || ''
    )
    const { message } = parseErrors(resp)

    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }

    const { availableTimes } = parseAvailableTimesRemoteGql(
      resp.items,
      defaultAvailableTimes,
      duration,
      usesLocalTimezone ? meetingMomentCode : timezone.momentCode
    )

    yield this.modules.availableTimes.set(availableTimes)
  }

  *pollUntilResult(
    externalId: string,
    slug: string,
    teamSlug: string,
    momentCode: string,
    hostMemberId?: string
  ) {
    // poll until at least one meeting is fetched
    let refetch = true
    const oldTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)
    const oldCount = oldTimes.sort.length
    while (refetch) {
      const error: boolean = yield this.fetchAndParseAvailableTimes(
        externalId,
        slug,
        teamSlug,
        momentCode,
        hostMemberId
      )
      if (error) {
        return true
      }
      const availableTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)
      const dynamicLoading: DynamicLoading = yield select(DynamicLoadingModule.selectDynamicLoading)
      refetch =
        availableTimes.sort.length === oldCount &&
        dynamicLoading.hasNextPage &&
        !dynamicLoading.fetching
    }
  }

  // This saga sets up the data required for rehydrating a previously completed booking.
  public *completedBooking(externalId: string): {} {
    const client = new Client()
    const resp: HandledResp = yield call(client.fetchBookedAppointment, externalId)

    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }
    // get timezone data for BookedAppointment.timezone
    const parsedTimezones = yield this.fetchTimezones()

    const booking = transformRemoteAttendee(resp, parsedTimezones)
    // set meetings based on MeetingTypeNode
    yield this.setMeetingFromMeetingTypeNode(resp.items.data.getAttendeeById?.meeting.meetingType)

    // proceed if no errors
    if (booking === null) {
      yield this.modules.session.setSessionMeta('error', 'error404')
      return true
    }

    yield this.setProfile(
      resp.items.data.getAttendeeById?.meeting.meetingType,
      resp.items.data.getAttendeeById?.meeting.meetingType.team
    )

    yield this.modules.bookedAppointment.set(booking)
  }

  // This saga sets up the data required for giving the user an interface for booking,
  // cancellation, or rescheduling
  public *updateBooking(booking: string) {
    const session: Session = yield select(SessionModule.selectSession)
    const client = new Client()
    const resp: HandledResp = yield call(client.fetchBookedAppointmentReschedule, booking)

    // generate new organization state
    yield this.setProfile(
      resp.items.data.getAttendeeById?.meeting.meetingType,
      resp.items.data.getAttendeeById?.meeting.meetingType.team
    )
    // get timezone data for BookedAppointment.timezone

    const parsedTimezones: TimezoneRemote[] = yield this.fetchTimezones()

    // Connection parse and error handle
    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      yield this.modules.session.setSessionMeta('error', 'error404')
      return true
    }

    // Server shape error handle
    if (!resp?.items?.data?.getAttendeeById) {
      yield this.modules.error.handleShowError(FETCH_BOOKED, Intent.DANGER, true)
      // default to meetings if errors
      // TODO redirect
      yield this.modules.session.setSessionMeta('error', 'error404')
      return true
    }
    const completeBooking = transformRemoteAttendee(resp, parsedTimezones)

    if (completeBooking === null) {
      yield this.modules.session.setSessionMeta('error', 'error404')
      return true
    }
    const { meeting } = completeBooking

    const start = completeBooking.meeting ? completeBooking.meeting.start : ''
    const timezone = completeBooking.timezone ? completeBooking.timezone.momentCode : ''
    const hasError = !start || !timezone || !meeting

    // Server shape error handle
    if (hasError) {
      yield this.modules.error.handleShowError(FETCH_BOOKED, Intent.DANGER, true)
      yield this.modules.session.setSessionMeta('error', 'error404')
      // default to meetings if errors
      // TODO redirect
      return true
    }

    yield this.modules.bookedAppointment.set(completeBooking)
    // set meeting from booked appointment
    const meetingType: Meeting = yield this.setMeetingFromMeetingTypeNode(
      resp.items.data.getAttendeeById.meeting.meetingType
    )

    // Only parse reschedule times if it is a reschedule
    if (session.sessionType === 'reschedule') {
      yield this.parseReschedule(resp.items.data.getAttendeeById.meeting, timezone)
    } else {
      // cancel or complete flow
      const duration = meetingType.duration || 0
      const times = parseAvailableTimes([start], duration, timezone)

      const oldTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)
      yield this.modules.availableTimes.set({ ...oldTimes, ...times, selected: start })
    }
  }

  *parseReschedule(meeting: SchedulerMeetingNode, timezone: string) {
    if (meeting?.reschedulableTimes?.data) {
      const {
        reschedulableTimes: { hasNextPage, nextPageStart, data },
        meetingType: { duration },
      } = meeting
      // Incoming duration is in seconds rather than minutes. Convert to seconds before parsing.
      const durationInMinutes = duration / 60
      const { availableTimes, bucketedTimes } = parseAvailableTimesRemote(
        data,
        durationInMinutes,
        timezone
      )

      yield this.modules.availableTimes.set(availableTimes)
      yield this.modules.bucketedTimes.set(bucketedTimes)
      yield this.modules.dynamicLoading.set({
        hasNextPage: hasNextPage || false,
        nextPageStart,
        fetching: false,
      })
    }
  }

  //
  // private helper sagas
  //

  *fetchTimezones() {
    // get available timezones
    const timezoneModal: TimezoneModal = yield select(TimezoneModalModule.selectTimezoneModal)
    if (!timezoneModal.timezones.length) {
      const client = new Client()
      const { items: resp } = yield call(client.getTimeZones)
      const parsedTimezones: TimezoneRemote[] = yield call(parseTimezonesRemote, resp)
      const computedTimezones: ReadonlyArray<TimezoneOption> = yield call(
        computeTimezoneValues,
        parsedTimezones
      )
      yield this.modules.timezoneModal.setTimezones(computedTimezones)
      return parsedTimezones
    } else {
      return parseTimezoneOptionsToTimezonesRemote(timezoneModal.timezones)
    }
  }

  *setProfile(
    meetingType: SchedulerMeetingTypeNode | undefined,
    team: SchedulerTeamNode | undefined
  ) {
    if (!meetingType || !team) {
      return
    }
    const newOrganizationState = parseTeamAndMeetingTypeToOrganization(meetingType, team)
    yield this.modules.organization.set(newOrganizationState)
  }

  // TODO when Team type is refactored to use CodeGen types this can be removed
  *setProfileLegacy(team: Team | undefined) {
    if (!team) {
      return
    }
    const newOrganizationState = parseTeamToOrganizationLegacy(team)
    yield this.modules.organization.set(newOrganizationState)
  }

  *setMeetingFromMeetingTypeNode(meetingType?: SchedulerMeetingTypeNode) {
    if (!meetingType) {
      return
    }
    const {
      description,
      duration,
      id,
      image,
      location,
      locationType,
      name,
      price,
      priceCurrency,
      priceFormatted,
      slug,
      team,
      usesLocalTimezone,
      hostAssignmentStrategy,
    } = meetingType
    const meeting: Meeting = {
      description,
      duration: duration / 60 || 0,
      id,
      image,
      location,
      locationType: locationType || 'PLACE',
      name: name || '',
      price,
      priceCurrency,
      priceFormatted,
      teamSlug: team.slug || '',
      slug: slug || '',
      timezone: defaultTimezone,
      usesLocalTimezone,
      hostAssignmentStrategy: hostAssignmentStrategy,
      hostMembers: [],
      selectedHostMember: null,
    }
    yield this.modules.meetings.set({ items: { [id]: meeting }, selected: id, sort: [id] })
    return meeting
  }

  public *fetchAndParseAvailableTimes(
    meetingId: string,
    slug: string,
    teamSlug: string,
    timezone: string,
    hostMemberId?: string
  ) {
    const dynamicLoading: DynamicLoading = yield select(DynamicLoadingModule.selectDynamicLoading)
    const { nextPageStart: start, fetching } = dynamicLoading

    if (fetching) {
      return false
    }
    yield this.modules.dynamicLoading.set({ ...dynamicLoading, fetching: true })

    const client = new Client()
    const resp: HandledResp = meetingId
      ? yield call(client.getRescheduleTimes, meetingId, start)
      : yield call(client.getAvailableTimes, slug, teamSlug, start, undefined, hostMemberId || '')

    const { message } = parseErrors(resp)
    if (message) {
      yield this.modules.error.handleShowError(message, Intent.DANGER)
      return true
    }

    const oldTimes: AvailableTimes = yield select(AvailableTimesModule.selectAvailableTimes)
    const meeting: Meeting = yield this.modules.meetings.getSelectedMeeting()

    const { availableTimes, bucketedTimes, hasNextPage, nextPageStart } = meetingId
      ? parseRescheduleTimesRemoteGql(resp.items, oldTimes, meeting.duration, timezone)
      : parseAvailableTimesRemoteGql(resp.items, oldTimes, meeting.duration, timezone)

    yield this.modules.availableTimes.set(availableTimes)
    yield this.modules.bucketedTimes.set(bucketedTimes)
    yield this.modules.dynamicLoading.set({ hasNextPage, nextPageStart, fetching: false })
    return false
  }
}
