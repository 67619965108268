import React from 'react'
import styled from 'styled-components'
import { ErrorMessage, ErrorType } from 'components/molecules/error_message/error_message'
import { errorPageStyles } from 'assets/shared_css_styles'
import { appColors } from 'assets/app_colors'
import { spacing } from 'util/style_utils'

interface ErrorPageProps {
  readonly type: ErrorType
  readonly isFullScreen: boolean
}
export const ErrorPage: React.FC<ErrorPageProps> = ({ type, isFullScreen }) => (
  <ErrorPageContainer isFullScreen={isFullScreen} type={type}>
    <ErrorMessage type={type} />
  </ErrorPageContainer>
)

const ErrorPageContainer = styled.div<ErrorPageProps>`
  ${errorPageStyles}
  ${({ isFullScreen }: ErrorPageProps) =>
    isFullScreen
      ? `display: flex; align-items: center; justify-content: center;`
      : `margin-top: 16px;`}

  padding: ${spacing(0, 2)};
  position: absolute;

  #warning-background {
    ${({ type }: ErrorPageProps) => (type === 'noMeetings' ? `fill: ${appColors.gray500}` : '')};
  }
  svg {
    width: ${spacing(8)};
  }

  @media all and (min-width: 699px) {
    ${({ isFullScreen }: ErrorPageProps) =>
      !isFullScreen &&
      `width: 75vw;
        margin: ${spacing(4)} 12.5vw;
        height: calc(100% - 64px);
        background: ${appColors.lightBackground};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      `}
  }
`
