import moment from 'moment-timezone'

export const computeDisplayLong = (
  startWithZone: moment.Moment,
  endWithZone: moment.Moment,
  timezone: string
): string => {
  const currentWeekStart = moment.tz(timezone).startOf('week')
  const nextWeekStart = moment.tz(timezone).add(1, 'week').startOf('week')

  // If week is current week, return string of "This week"
  if (startWithZone.format() === currentWeekStart.format()) {
    return 'THIS WEEK'
  }

  // If week is next week, return string of "Next week"
  if (startWithZone.format() === nextWeekStart.format()) {
    return 'NEXT WEEK'
  }

  // If week crosses month threshold i.e. December 27th - January 2nd, return correct string
  if (startWithZone.month() !== endWithZone.month()) {
    return `${startWithZone.format('MMMM Do')} - ${endWithZone.format('MMMM Do')}`
  }

  return `${startWithZone.format('MMMM Do')} - ${endWithZone.format('Do')}`
}

export const computeIsToday = (time: string, timezone: string): boolean => {
  const today = moment().tz(timezone).startOf('day').format()
  return moment(time).tz(timezone).startOf('day').format() === today
}
