import moment from 'moment-timezone'
import { AvailableTime, AvailableTimes } from '../redux/available_times/available_times_core'
import { BaseMap, getRepo } from 'common/redux/base_map'
import { computeDisplayLong, computeIsToday } from './remote/available_times_transform_util'
import {
  BucketedTimes,
  Day,
  TimeBucket,
  BucketUnit,
} from 'common/redux/bucketed_times/bucketed_times_core'

// Converts time string into AvailableTime object
export const toAvailableTime = (
  timeString: string,
  duration: number,
  timezone: string
): AvailableTime => {
  const timeWithZone = moment(timeString).tz(timezone)
  const endMoment = moment(timeString).tz(timezone).add(duration, 'minutes')
  return {
    id: timeString,
    date: timeWithZone.format('ddd, ll'),
    dateMobile: timeWithZone.format('ddd L'),
    dateLong: timeWithZone.format('dddd, LL'),
    display: timeWithZone.format('LT').toLocaleLowerCase(),
    endValue: endMoment.format(),
    end: endMoment.format('LT').toLocaleLowerCase(),
  }
}

// Converts array of times into AvailableTimes
export const parseAvailableTimes = (
  rawTimes: ReadonlyArray<string>,
  duration: number,
  timezone: string
): AvailableTimes => {
  return getRepo<AvailableTime>(
    rawTimes.reduce((acc, id) => {
      return {
        ...acc,
        [id]: toAvailableTime(id, duration, timezone),
      }
    }, {})
  )
}

// Separate times into days by start of day based on timezone
const getBucketedDays = (times: ReadonlyArray<string>, timezone: string): ReadonlyArray<Day> => {
  const days = times.reduce((acc, time) => {
    const start = moment(time).tz(timezone).startOf('day').format()
    // if this is a new day, create a new day node.
    // if there is already a node for the day, add the time to the appropriate day
    return acc[start] === undefined
      ? {
          ...acc,
          [start]: getNewDay(time, start, timezone),
        }
      : {
          ...acc,
          [start]: {
            ...acc[start],
            times: [...acc[start].times, time],
          },
        }
  }, {} as BaseMap<Day>)

  const array: ReadonlyArray<Day> = Object.keys(days).map(day => days[day])
  return array
}

// Build a new day bucket object
const getNewDay = (time: string, start: string, timezone: string): Day => {
  const timeWithZone = moment(time).tz(timezone)

  return {
    start,
    end: timeWithZone.endOf('day').format(),
    dayNumber: timeWithZone.format('D'),
    dayNumberSuffixed: timeWithZone.format('Do'),
    dayName: moment(time).tz(timezone).format('dddd'),
    dayNameShort: moment(time).tz(timezone).format('ddd'),
    month: timeWithZone.format('MMMM'),
    year: timeWithZone.format('YYYY'),
    times: [time],
    isToday: computeIsToday(time, timezone),
  }
}

// Build a new TimeBucket object
const getNewBucket = (
  day: Day,
  start: string,
  bucketType: BucketUnit,
  timezone: string
): TimeBucket => {
  const startWithZone = moment(start).tz(timezone)
  const endWithZone = moment(start).tz(timezone).endOf(bucketType)

  return {
    displayLong: computeDisplayLong(startWithZone, endWithZone, timezone),
    displayShort: `(${startWithZone.format('M/D')} - ${endWithZone.format('M/D')})`,
    unit: bucketType,
    days: [day],
  }
}

// Transform days into bucketedTimes state object
const getBuckets = (
  days: ReadonlyArray<Day>,
  bucketType: BucketUnit,
  timezone: string
): BucketedTimes => {
  const buckets = days.reduce((acc, day) => {
    const start = moment(day.start).tz(timezone).startOf(bucketType).format()
    return acc[start] === undefined
      ? {
          ...acc,
          [start]: getNewBucket(day, start, bucketType, timezone),
        }
      : {
          ...acc,
          [start]: {
            ...acc[start],
            days: [...acc[start].days, day],
          },
        }
  }, {} as BaseMap<TimeBucket>)

  const array: ReadonlyArray<TimeBucket> = Object.keys(buckets)
    .filter(w => buckets[w].displayLong !== 'THIS WEEK' && buckets[w].displayLong !== 'NEXT WEEK')
    .map(week => buckets[week])

  const thisWeek = Object.keys(buckets)
    .filter(w => buckets[w].displayLong === 'THIS WEEK')
    .map(w => buckets[w])
  const nextWeek = Object.keys(buckets)
    .filter(w => buckets[w].displayLong === 'NEXT WEEK')
    .map(w => buckets[w])

  return [...thisWeek, ...nextWeek, ...array]
}

export const parseBucketedTimes = (
  rawTimes: ReadonlyArray<string>,
  timezone: string
): BucketedTimes => {
  const bucketedDays = getBucketedDays(rawTimes, timezone)
  const bucketUnit = 'week'
  return getBuckets(bucketedDays, bucketUnit, timezone)
}
