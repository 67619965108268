import { HandledResp } from '../../api/client'
import { UpdatePayload } from './submit_update_booking_core'
import { AppState } from '../sitka'
import { appStrings } from 'assets/app_strings'

const { CONNECT } = appStrings.errorMessage

interface ErrorStatus {
  message?: string
  code?: number
}

export const parseErrors = (response: HandledResp): ErrorStatus => {
  // No Connection to server
  if (!response.status || !response.items) {
    return { message: CONNECT }
  }

  // Any other errors
  const errors = response?.items?.errors
  const error = errors && (Array.isArray(errors) ? errors[0] : errors)
  if (error) {
    return {
      message: `${response.status}: ${response.statusText} - ${error.message}`,
      code: response.status,
    }
  }
  return {}
}

export const getUpdateBookingPayload = (state: AppState, type: string): UpdatePayload => {
  const {
    bookedAppointment: { externalId: remoteId },
    submitUpdateBooking: { updateBookingReason: message },
    availableTimes: { selected },
  } = state
  const id = remoteId ? remoteId : ''
  // return cancelAttendee payload
  if (type === 'cancel') {
    return {
      id,
      message,
    }
  }
  // return rescheduleAttendee payload
  return {
    id,
    message,
    start: selected,
  }
}
