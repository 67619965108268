import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Intent, Position, Toaster } from '@blueprintjs/core'
import { X } from 'react-feather'
import { Helmet } from 'react-helmet'

import { AnimationWrapper } from 'pages/base/animation_wrapper'
import { appColors } from 'assets/app_colors'
import { AppState, AppModules, sitka } from 'common/redux/sitka'
import { BookedAppointment } from 'common/redux/booked_appointment/booked_appointment_core'
import { BootstrapModule } from 'common/redux/bootstrap/bootstrap_module'
import { Error } from 'common/redux/error/error_core'
import { ErrorModule } from 'common/redux/error/error_module'
import { getGoogleMapScript } from 'util/components/page_container'
import { HeaderContainer } from 'components/molecules/header/header_container'
import { ModalContainer } from 'components/molecules/modal_container/modal_container'
import { Organization } from 'common/redux/organization/organization_core'
import { OrganizationModule } from 'common/redux/organization/organization_module'
import { ProgressSpinner } from 'components/molecules/progress_spinner/progress_spinner'
import { RedirectWrapper, calculateMetaTags } from 'routing_util'
import { Session, SessionModes } from 'common/redux/session/session_core'
import { SessionModule } from 'common/redux/session/session_module'

import { CompletePage } from 'pages/complete/complete_page'
import { ErrorPage } from 'pages/error/error_page'
import { SelectMeetingPage } from 'pages/select_meeting_type/select_meeting_type_page'
import { SelectTimePage } from 'pages/select_time/select_time_page'
import { UpdateBookingPage } from 'pages/update_booking/update_booking_page'
import { ErrorType } from 'components/molecules/error_message/error_message'
import { Meetings } from 'common/redux/meetings/meetings_core'
import { ContainerPropsModalMode } from 'assets/shared_css_styles'
import { SelectTimeOrHostMemberPage } from 'pages/select_time_or_host_member/select_time_or_host_member_page'
import { SelectTimeOrConfirmInfoPage } from 'pages/select_time_or_confirm_info/select_time_or_confirm_info_page'
import { ConfirmInfoPage } from 'pages/confirm_info/confirm_info_page'

declare global {
  interface Window {
    prerenderReady: boolean
  }
}

const AppToaster = Toaster.create({ position: Position.TOP })
const showToast = (message: string, intent: Intent, translate: boolean) => {
  return AppToaster.show({ message, intent, icon: 'issue', className: translate ? '' : 'excl-lz' })
}

interface AppProps {
  readonly bootstrapModule: BootstrapModule
  readonly errorModule: ErrorModule
  readonly organizationModule: OrganizationModule
  readonly sessionModule: SessionModule

  readonly routePath?: string
  readonly session: Session
  readonly error: Error
  readonly organization: Organization
  readonly bookedAppointment: BookedAppointment
  readonly meetings: Meetings
}

const redirectComponent: React.FC = () => {
  window.location.href = 'https://www.appointlet.com/'
  return null
}

interface RouteStatus {
  readonly [key: string]: string
}

export const routes = [
  { path: '/:team', Component: SelectMeetingPage },
  { path: '/:team/:meeting', Component: SelectTimeOrHostMemberPage },
  { path: '/:team/:meeting/:time_or_host_member', Component: SelectTimeOrConfirmInfoPage },
  { path: '/:team/:meeting/:time_or_host_member/:time', Component: ConfirmInfoPage },
  { path: '/meeting/:meeting_id', Component: CompletePage },
  { path: '/m/:meeting_id/reschedule', Component: SelectTimePage },
  { path: '/m/:meeting_id/cancel', Component: UpdateBookingPage },
  { path: '/m/:meeting_id/conference', Component: () => null },
]

// App bootstrapping. Must be in a lower component to have access to reactRouter
interface BootstrapperProps {
  readonly bootstrapModule: BootstrapModule
}

const Bootstrapper: React.FC<BootstrapperProps> = ({ bootstrapModule }) => {
  const location = useLocation()
  useEffect(() => {
    bootstrapModule.handleBootstrap(location.search)
    const script = getGoogleMapScript()
    document.body.appendChild(script)
    // eslint-disable-next-line
  }, [])
  return null
}

const Component: React.FC<AppProps> = ({
  bootstrapModule,
  errorModule: { handleClearError },
  organizationModule,
  sessionModule,
  error,
  organization,
  meetings: { items: meetings, selected: selectedMeeting },
  routePath,
  session: { spinner, redirect, redirectReplace: replace, location, sessionType, mode },
}) => {
  useEffect(() => {
    if (error.shown === false) {
      handleClearError()
      showToast(error.text, error.intent, error.translate)
    }
  }, [error.shown, error.text, error.intent, error.translate, handleClearError])

  useEffect(() => {
    if (redirect) {
      sessionModule.handleClearRedirect()
    }
  }, [redirect, sessionModule])

  if (sessionType === 'error') {
    let type: ErrorType = 'notFound'
    const isModal = mode === 'modal'

    switch (location) {
      case 'serverError':
        type = 'serverError'
        break
      case 'error404':
      default:
        break
    }
    return (
      <>
        {isModal && (
          <CloseButtonContainer onClick={() => sessionModule.handleCloseModal()}>
            <X />
          </CloseButtonContainer>
        )}
        <ErrorPage type={type} isFullScreen={!isModal} />
      </>
    )
  }

  const { metaTitle, metaImage, metaDescription } = calculateMetaTags(
    location,
    organization,
    meetings[selectedMeeting]
  )

  return (
    <Router>
      <Bootstrapper bootstrapModule={bootstrapModule} />
      <Route exact path={'/'} component={redirectComponent} />
      <RedirectWrapper {...{ redirect, replace }} />
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="twitter:title" content={metaTitle} />
        {metaImage && <meta property="twitter:image" content={metaImage} />}
        {metaImage && <meta name="og:image" content={metaImage} />}
        <meta name="twitter:card" content="summary_large_image" />
        {metaDescription && <meta property="og:description" content={metaDescription} />}
      </Helmet>
      <Container
        {...{
          bootstrapModule,
          organizationModule,
          sessionModule,

          mode,
          redirect,
          routePath,
          organization,
          spinner,
        }}
      />
    </Router>
  )
}

interface ContainerProps {
  readonly bootstrapModule: BootstrapModule
  readonly organizationModule: OrganizationModule
  readonly sessionModule: SessionModule

  readonly mode?: SessionModes
  readonly routePath?: string
  readonly spinner: boolean
}

const Container: React.FC<ContainerProps> = ({
  bootstrapModule,
  organizationModule: { setLocalization },
  sessionModule: { handleCloseModal, handleSetSessionMeta },

  mode,
  routePath,
  spinner,
}) => {
  const isModal = mode === 'modal'
  const [updateStack, setUpdateStack] = useState<RouteStatus>(
    routes.reduce((acc, value) => ({ ...acc, [value.path]: 'EXITED' }), {})
  )

  const [lastLocation, setLastLocation] = useState<string>('')
  const [goingForward, setGoingForward] = useState<boolean>(true)
  const pathname = useLocation().pathname

  if (lastLocation !== pathname) {
    const goingForward =
      (pathname.split('/').length > lastLocation.split('/').length ||
        pathname.startsWith('/meeting/')) &&
      !lastLocation.startsWith('/meeting/')
    setGoingForward(goingForward)
    setLastLocation(pathname)
  }

  let matches = 0

  return (
    <PageContainer isModal={isModal}>
      <ContainerComponent isModal={isModal}>
        <HeaderContainer />
        {routes.map(({ path, Component }) => (
          <Route exact key={path} path={path}>
            {({ match }: any) => {
              // this will skip the restricted names 'meeting' and 'm'
              if (match?.params?.team === 'meeting' || match?.params?.team === 'm') {
                match = null
              }
              // counts the number of matched routes
              if (match) {
                matches++
              }
              // if we are at the end of the matched routes and how not matched show an error page
              if (!matches && routes[routes.length - 1].path === path) {
                // No routes match, set language then show 404 error page
                setLocalization('en')
                handleSetSessionMeta('error', 'error404')
              }
              // Initial page load
              if (
                !Object.values(updateStack).reduce(
                  (acc, value) => (value === 'EXITED' && acc === false ? false : true),
                  false
                ) &&
                match
              ) {
                if (routePath !== match?.path) {
                  bootstrapModule.handleNavigation(match)
                }
              }

              const render =
                match !== null && routePath !== undefined && match.path === routePath && !spinner

              return (
                <CSSTransition
                  onEnter={() => setUpdateStack({ ...updateStack, [path]: 'ENTER' })}
                  onEntered={() => {
                    // sets prerenderReady after page loads
                    window.prerenderReady = true
                    setUpdateStack({ ...updateStack, [path]: 'ENTERED' })
                  }}
                  onExit={() => setUpdateStack({ ...updateStack, [path]: 'EXIT' })}
                  onExited={() => setUpdateStack({ ...updateStack, [path]: 'EXITED' })}
                  in={render}
                  timeout={250}
                  classNames={goingForward ? 'forward' : 'backward'}
                  unmountOnExit
                  mountOnEnter
                >
                  <AnimationWrapper startPosition={20} appearDuration={250} isModal={isModal}>
                    <main className="content-container" id="content-container">
                      <Component />
                    </main>
                  </AnimationWrapper>
                </CSSTransition>
              )
            }}
          </Route>
        ))}
        <ModalContainer />
        {spinner && <ProgressSpinner />}
        {isModal && (
          <CloseButtonContainer onClick={() => handleCloseModal()}>
            <X />
          </CloseButtonContainer>
        )}
      </ContainerComponent>
    </PageContainer>
  )
}

export const App = connect((state: AppState) => {
  const modules: AppModules = sitka.getModules()
  return {
    bootstrapModule: modules.bootstrap,
    errorModule: modules.error,
    organizationModule: modules.organization,
    sessionModule: modules.session,

    bookedAppointment: state.bookedAppointment,
    error: state.error,
    organization: state.organization,
    meetings: state.meetings,
    routePath: state.bootstrap.currentPage?.path,
    session: state.session,
  }
})(Component)

const CloseButtonContainer = styled.div`
  position: fixed;
  left: calc(87.5vw + 12px);
  width: 32px;
  height: 32px;
  background-color: ${appColors.gray600};
  border-radius: 100%;
  top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${appColors.gray700};
  }

  svg {
    color: ${appColors.modalCloseButton};
  }
`

const ContainerComponent = styled.div<ContainerPropsModalMode>`
  height: 100%;
  display: flex;
  background-color: #f7f8fa;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  .content-container {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media all and (min-width: 699px) {
    .content-container {
      height: calc(100vh - ${({ isModal }) => (isModal ? 135 : 71)}px);
    }
  }
`

const PageContainer = styled.div<ContainerPropsModalMode>`
  height: 100%;
  ${({ isModal }) => isModal && `padding: 32px 12.5vw;`}
`
