import React from 'react'
import moment from 'moment-timezone'

interface TimerProps {
  readonly currentTime: string
  readonly timerString: string
  readonly timezoneRegion: string
}

interface TimerState {
  readonly currentTime: string
}

export interface Timer {}
export class Timer extends React.Component<TimerProps, TimerState> {
  startTimer: number

  constructor(props: TimerProps) {
    super(props)
    const { currentTime } = this.props
    this.state = { currentTime }
    this.startTimer = -1
  }

  componentDidMount(): void {
    this.startTimer = window.setInterval(this.updateTime.bind(this), 1000)
  }

  componentWillUnmount(): void {
    clearInterval(this.startTimer)
  }

  updateTime = () => {
    // todo: investigate: might be causing console depreciation warning when changing timezone
    const { timezoneRegion } = this.props
    const newTime = moment(new Date()).tz(timezoneRegion).format('LT').toLocaleLowerCase()

    this.setState({ currentTime: newTime })
  }

  render(): JSX.Element {
    const { timerString } = this.props
    const { currentTime } = this.state
    return (
      <div>
        <span style={{ marginRight: '3px' }}>{timerString}</span>
        <span className="excl-lz">{currentTime}</span>
      </div>
    )
  }
}
