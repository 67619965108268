import React, { useEffect, useState } from 'react'
import { FieldProps } from './custom_field'
import { Field, useField } from 'react-final-form'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { Position } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import { appColors } from '../../../assets/app_colors'
import calendarIcon from '../../../assets/Icons/icn-cal-input.svg'
import { formatDateForStore } from 'util/components/custom_date_field'

interface CustomDateProps extends FieldProps {
  readonly type: string
  readonly id: string
  readonly timezoneCode: string
}

export const CustomDateField: React.FC<CustomDateProps> = ({
  fieldId,
  value,
  type,
  timezoneCode,
}) => {
  const [width, setWidth] = useState<number | null>()
  const [dateValue, setDateValue] = useState<string>(value ? (value as string) : '')

  const formField = useField(fieldId)

  useEffect(() => {
    formField.input.onChange(dateValue)
  }, [formField, dateValue])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = () => {
    // This is to determine and update the size of the window, and save dimensions in state
    const w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth

    setWidth(width)
  }

  const DesktopDate: React.FC = () => {
    const value = !!dateValue
      ? moment(dateValue as string)
          .tz(timezoneCode)
          .toDate()
      : null

    const parseDate = (dateValue: string): Date =>
      !!dateValue
        ? moment(dateValue as string)
            .tz(timezoneCode)
            .toDate()
        : moment().tz(timezoneCode).toDate()

    const formatDate = (handledValue: Date): string =>
      handledValue.toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })

    const placeholder = moment.localeData().longDateFormat('L').toLowerCase()

    const onChange = (handledValue: Date) =>
      setDateValue(formatDateForStore(handledValue, timezoneCode))

    return (
      <DateInput
        inputProps={{
          className: 'desktop-date-field',
        }}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        parseDate={parseDate}
        formatDate={formatDate}
        popoverProps={{ position: Position.TOP }}
        minDate={moment('1900-01-01').toDate()}
        maxDate={moment().add(30, 'years').toDate()}
      />
    )
  }
  const isMobile = width && width < 698
  return (
    <DateFieldContainer>
      {isMobile ? (
        <MobileDateField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDateValue(e.target.value)}
          name={fieldId}
          component="input"
          type={type}
          value={dateValue}
        />
      ) : (
        <DesktopDate />
      )}
    </DateFieldContainer>
  )
}

const MobileDateField = styled(Field).attrs(() => ({
  type: 'date',
}))`
width: 171px;
color: ${props => (props.value === '' ? `${appColors.placeHolder} !important` : 'inherit')};
padding: 5px 5px 5px 10px;
font-family: "Helvetica Neue", "Nunito Sans", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif, "Icons16" !important;
::-webkit-inner-spin-button {
  display: none;
  &:before {
    content: "";
    padding: 16px 10px 16px 12px;
    background-image: url(${calendarIcon});
    background-position: left center;
    background-repeat: no-repeat;
    width: 14px;
    height: 13px;
  }
`
const DateFieldContainer = styled.div`
  &&&& .desktop-date-field {
    position: relative;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px rgba(138, 145, 153, 0.41);

    input {
      border: none;
      width: 109px;
      padding: 5px 5px 5px 10px;
      box-shadow: none;
    }
    &:before {
      content: '';
      padding: 11px 13px 13px 3px;
      margin-left: 12px;
      background-image: url(${calendarIcon});
      background-position: left center;
      background-repeat: no-repeat;
      width: 14px;
      height: 13px;
    }
  }
`
